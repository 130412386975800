import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { EuiSpacer } from '@elastic/eui';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
import TimeLine from 'components/Products/CommentList/MqttIFTTTList';
export const _frontmatter = {
  "title": "Set up a Passive FTP Server for your WQHD Camera using Docker",
  "path": "/Frequently_Asked_Question/FTP_Passive_Server_for_your_WQHD_Camera_using_Docker/",
  "dateChanged": "2022-05-24",
  "author": "Mike Polinowski",
  "excerpt": "I want to use my cameras FTP service to upload alarm recordings and snapshot series to my Linux Server (Raspberry Pi).",
  "image": "../../FAQ_SearchThumb.png",
  "social": "/images/Search/FAQ_SearchThumb.webp",
  "toc": "/images/Search/TOC_Icons/Wiki_Tiles_FAQs_white.webp",
  "chapter": "FAQs",
  "faq": "WQHD & UHD Cameras"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <SEOHelmet title='FAQs - Set up a Passive FTP Server for your WQHD Camera using Docker' dateChanged='2022-05-24' author='Mike Polinowski' tag='INSTAR IP Camera' description='I want to use my cameras FTP service to upload alarm recordings and snapshot series to my Linux Server (Raspberry Pi).' image='/images/Search/FAQ_SearchThumb.png' twitter='/images/Search/FAQ_SearchThumb.webp' location={props.location.pathname} mdxType="SEOHelmet" />
    <BreadCrumbs locationBC={props.location} locationEN={props.location.pathname} locationDE='/de/Frequently_Asked_Question/FTP_Passive_Server_for_your_WQHD_Camera_using_Docker/' locationFR='/fr/Frequently_Asked_Question/FTP_Passive_Server_for_your_WQHD_Camera_using_Docker/' crumbLabel="FAQs" mdxType="BreadCrumbs" />
    <h1 {...{
      "id": "set-up-a-passive-ftp-server-for-your-wqhd-camera-using-docker",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#set-up-a-passive-ftp-server-for-your-wqhd-camera-using-docker",
        "aria-label": "set up a passive ftp server for your wqhd camera using docker permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Set up a Passive FTP Server for your WQHD Camera using Docker`}</h1>
    <p><strong parentName="p">{`Update`}</strong>{`: This tutorial works with both `}<strong parentName="p">{`Active`}</strong>{` (`}<strong parentName="p">{`PORT Mode`}</strong>{`) and `}<strong parentName="p">{`Passive`}</strong>{` (`}<strong parentName="p">{`PASV Mode`}</strong>{`) uploads. If you only need `}<strong parentName="p">{`Port Mode`}</strong>{` please read our tutorial `}<a parentName="p" {...{
        "href": "/en/Frequently_Asked_Question/FTP_Server_for_your_WQHD_Camera_using_Docker/"
      }}>{`Set up an FTP Server for your WQHD Camera using Docker`}</a>{`.`}</p>
    <p><strong parentName="p">{`Update`}</strong>{`: Extended tutorial to `}<a parentName="p" {...{
        "href": "/en/Frequently_Asked_Question/FTP_Secure_Server_for_your_WQHD_Camera_using_Docker/"
      }}>{`Set up a Secure FTP Server for your WQHD Camera using Docker`}</a>{` by adding TLS encryption.`}</p>
    <p><strong parentName="p">{`Q`}</strong>{`: I want to use my cameras FTP service to upload alarm recordings and snapshot series to my Linux Server (Raspberry Pi).`}</p>
    <p><strong parentName="p">{`A`}</strong>{`: The simplest way would be to use your `}<a parentName="p" {...{
        "href": "/en/Motion_Detection/Router_as_a_FTP_Server/"
      }}>{`Internet Router as FTP Server`}</a>{`. If you want to use a Linux Server like an Raspberry Pi we recommend using the `}<a parentName="p" {...{
        "href": "/en/Frequently_Asked_Question/Using_the_sFTP_Service_with_your_WQHD_Camera/"
      }}>{`sFTP Service`}</a>{` instead of FTP or FTPS. But of course you can also set up your own FTP server instead. I would recommend building an `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`vsftpd`}</code>{` Docker image for this task.`}</p>
    {/* TOC */}
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#preparing-the-docker-image"
        }}>{`Preparing the Docker Image`}</a>
        <ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "#ftp-user-login"
            }}>{`FTP User Login`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#ftp-user-configuration"
            }}>{`FTP User Configuration`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#server-configuration"
            }}>{`Server Configuration`}</a></li>
        </ul>
      </li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#starting-the-docker-container"
        }}>{`Starting the Docker Container`}</a>
        <ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "#build-the-image"
            }}>{`Build the Image`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#run-the-container"
            }}>{`Run the Container`}</a></li>
        </ul>
      </li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#connecting-an-instar-ip-camera"
        }}>{`Connecting an INSTAR IP Camera`}</a>
        <ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "#full-hd-cameras"
            }}>{`Full HD Cameras`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#wqhd-cameras"
            }}>{`WQHD Cameras`}</a></li>
        </ul>
      </li>
    </ul>
    {/* /TOC */}
    <h2 {...{
      "id": "preparing-the-docker-image",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#preparing-the-docker-image",
        "aria-label": "preparing the docker image permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Preparing the Docker Image`}</h2>
    <p>{`Start by creating a work directory and your `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`Dockerfile`}</code>{`, e.g. :`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}><span parentName="code" {...{
            "className": "token function"
          }}>{`mkdir`}</span>{` -p /opt/vsftpd/ftpuser `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`&&`}</span>{` `}<span parentName="code" {...{
            "className": "token builtin class-name"
          }}>{`cd`}</span>{` /opt/vsftpd
`}<span parentName="code" {...{
            "className": "token function"
          }}>{`nano`}</span>{` Dockerfile`}</code></pre></div>
    <p>{`And add the following content. Make sure port `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`20`}</code>{` - `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`21`}</code>{` as well as `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`4242`}</code>{` - `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`4243`}</code>{`, are being forwarded to the server. We will need these extra ports for the passive mode - set `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`pasv_min_port`}</code>{` and `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`pasv_max_port`}</code>{` accordingly in the `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`vsftpd.conf`}</code>{` configuration file below.`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "yml"
    }}><pre parentName="div" {...{
        "className": "language-yml"
      }}><code parentName="pre" {...{
          "className": "language-yml"
        }}><span parentName="code" {...{
            "className": "token comment"
          }}>{`# Dockerfile for vsftpd on CentOS7`}</span>{`
FROM centos`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`7`}</span>{`

MAINTAINER m.polinowski@instar.com

RUN yum `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{`y update; yum `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{`y install which vsftpd net`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{`tools vsftpd`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{`sysvinit; yum clean all

COPY vusers.txt /etc/vsftpd/
RUN db_load `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{`T `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{`t hash `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{`f /etc/vsftpd/vusers.txt /etc/vsftpd/vsftpd`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{`virtual`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{`user.db; rm `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{`v /etc/vsftpd/vusers.txt; \\
	chmod 600 /etc/vsftpd/vsftpd`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{`virtual`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{`user.db
COPY vsftpd.conf /etc/vsftpd/
COPY vsftpd.virtual /etc/pam.d/
RUN mkdir `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{`p /home/vftp/ftpuser; chown `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{`R ftp`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`ftp /home/vftp

EXPOSE 20 21 4242 4243

CMD `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"/usr/sbin/vsftpd"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"-obackground=NO"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span></code></pre></div>
    <p>{`This `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`Dockerfile`}</code>{` will take the CentOS as a base image - this can be replaced by any flavour of Enterprise Linux. The next step installs the `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`vsftpd`}</code>{` service and creates the FTP User account for us. After that we need to copy the following configuration files into the image - all of the need to be created in the same directory where we placed our `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`Dockerfile`}</code>{`:`}</p>
    <h3 {...{
      "id": "ftp-user-login",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#ftp-user-login",
        "aria-label": "ftp user login permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`FTP User Login`}</h3>
    <p><em parentName="p">{`vusers.txt`}</em></p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}>{`ftpuser
mypassword`}</code></pre></div>
    <p>{`This is the user we will have to use to connect to the FTP server - change both the username and password according to your needs.`}</p>
    <h3 {...{
      "id": "ftp-user-configuration",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#ftp-user-configuration",
        "aria-label": "ftp user configuration permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`FTP User Configuration`}</h3>
    <p><em parentName="p">{`vsftpd.virtual`}</em></p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}><span parentName="code" {...{
            "className": "token comment"
          }}>{`#%PAM-1.0`}</span>{`
auth       required     pam_userdb.so `}<span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`db`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`/etc/vsftpd/vsftpd-virtual-user
account    required     pam_userdb.so `}<span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`db`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`/etc/vsftpd/vsftpd-virtual-user
session    required     pam_loginuid.so`}</code></pre></div>
    <h3 {...{
      "id": "server-configuration",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#server-configuration",
        "aria-label": "server configuration permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Server Configuration`}</h3>
    <p><em parentName="p">{`vsftpd.conf`}</em></p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}><span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`anonymous_enable`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`NO
`}<span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`local_enable`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`YES
`}<span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`connect_from_port_20`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`YES
`}<span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`pasv_enable`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`YES
`}<span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`pasv_addr_resolve`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`YES
`}<span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`pasv_min_port`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`4242`}</span>{`
`}<span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`pasv_max_port`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`4243`}</span>{`
`}<span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`virtual_use_local_privs`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`YES
`}<span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`write_enable`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`YES
`}<span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`local_umask`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`022
`}<span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`pam_service_name`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`vsftpd.virtual
`}<span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`guest_enable`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`YES
`}<span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`user_sub_token`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token environment constant"
          }}>{`$USER`}</span>{`
`}<span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`local_root`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`/home/vftp/`}<span parentName="code" {...{
            "className": "token environment constant"
          }}>{`$USER`}</span>{`
`}<span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`chroot_local_user`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`YES
`}<span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`allow_writeable_chroot`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`YES
`}<span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`hide_ids`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`YES
`}<span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`xferlog_enable`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`YES
`}<span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`xferlog_file`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`/var/log/vsftpd.log`}</code></pre></div>
    <h2 {...{
      "id": "starting-the-docker-container",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#starting-the-docker-container",
        "aria-label": "starting the docker container permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Starting the Docker Container`}</h2>
    <h3 {...{
      "id": "build-the-image",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#build-the-image",
        "aria-label": "build the image permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Build the Image`}</h3>
    <p>{`With those 4 files in place we are now ready to build our Docker image:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}>{`docker build -t vsftpd_pasv `}<span parentName="code" {...{
            "className": "token builtin class-name"
          }}>{`.`}</span></code></pre></div>
    <h3 {...{
      "id": "run-the-container",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#run-the-container",
        "aria-label": "run the container permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Run the Container`}</h3>
    <p>{`Make sure that the FTP user directory exists and can be written to by your Docker user:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}><span parentName="code" {...{
            "className": "token function"
          }}>{`mkdir`}</span>{` -p /opt/vsftpd/ftpuser
`}<span parentName="code" {...{
            "className": "token function"
          }}>{`chmod`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`755`}</span>{` -R /opt/vsftpd/*`}</code></pre></div>
    <blockquote>
      <p parentName="blockquote">{`Make sure that the home directory `}<code parentName="p" {...{
          "className": "language-text"
        }}>{`/opt/vsftpd/ftpuser`}</code>{` is set to `}<code parentName="p" {...{
          "className": "language-text"
        }}>{`777`}</code>{` to prevent the FTP write error `}<strong parentName="p">{`ftp 550 Create directory operation failed.`}</strong></p>
    </blockquote>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}>{`docker run -d `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`\\`}</span>{`
    --name vsftpd `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`\\`}</span>{`
    --net`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`host `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`\\`}</span>{`
    --privileged `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`\\`}</span>{`
    --rm `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`\\`}</span>{`
    -v /opt/vsftpd/ftpuser/:/home/vftp/ftpuser/ `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`\\`}</span>{`
    vsftpd_pasv:latest`}</code></pre></div>
    <h2 {...{
      "id": "connecting-an-instar-ip-camera",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#connecting-an-instar-ip-camera",
        "aria-label": "connecting an instar ip camera permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Connecting an INSTAR IP Camera`}</h2>
    <p>{`Now you can also use the `}<strong parentName="p">{`PASV`}</strong>{` mode - e.g. in FileZilla Server:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/fa60ee13abd0dedd243db82b472a76fe/f53a0/FTP_Server_for_your_WQHD_Camera_using_Docker_01.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "54.78260869565218%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAIAAADwazoUAAAACXBIWXMAAA7rAAAO6wFxzYGVAAABVklEQVQoz4WS246CMBBA+QpBWqCwKhSFlt6glIuCcR822f//nA3goj64m5w005mcTGdS67sfCK/yjOWsyrleyXilu3G4fdX9WLUDKxuuu7q/UllnrCRCs9JY6fGQdA1sWjc8ABgCL7oDQxjslDk3lxurWlyojFdcdymVcc6TnCcZt1zgIyGAUhvg2zawHfgL2AKfCC10R5WhpmelwUTEGUtmGefcAh7a72IEQ9f1na33wIEuRCmVVBki60I1J6Y+4mPyLLsQBWgHvHD7KtuzvPZZiE/FGk/yFgS2A196vpefmWTH9ZchNzawbbCZWa4u+E/20A76EfSjIIqDcB9EBxTFXvABvNBH++Rv+R5RYS63qh2rdtT9FROxlPEb8y5jIjARKZXN8Kn7q+5Gc76lVE7JGfzEY3Pzi6y1cCzUkcrpLNSaTKlcwERMf66/irqX5lK2Y9kOP1xXbymTkIGxAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/fa60ee13abd0dedd243db82b472a76fe/e4706/FTP_Server_for_your_WQHD_Camera_using_Docker_01.avif 230w", "/en/static/fa60ee13abd0dedd243db82b472a76fe/d1af7/FTP_Server_for_your_WQHD_Camera_using_Docker_01.avif 460w", "/en/static/fa60ee13abd0dedd243db82b472a76fe/7f308/FTP_Server_for_your_WQHD_Camera_using_Docker_01.avif 920w", "/en/static/fa60ee13abd0dedd243db82b472a76fe/23a38/FTP_Server_for_your_WQHD_Camera_using_Docker_01.avif 1062w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/fa60ee13abd0dedd243db82b472a76fe/a0b58/FTP_Server_for_your_WQHD_Camera_using_Docker_01.webp 230w", "/en/static/fa60ee13abd0dedd243db82b472a76fe/bc10c/FTP_Server_for_your_WQHD_Camera_using_Docker_01.webp 460w", "/en/static/fa60ee13abd0dedd243db82b472a76fe/966d8/FTP_Server_for_your_WQHD_Camera_using_Docker_01.webp 920w", "/en/static/fa60ee13abd0dedd243db82b472a76fe/dac18/FTP_Server_for_your_WQHD_Camera_using_Docker_01.webp 1062w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/fa60ee13abd0dedd243db82b472a76fe/81c8e/FTP_Server_for_your_WQHD_Camera_using_Docker_01.png 230w", "/en/static/fa60ee13abd0dedd243db82b472a76fe/08a84/FTP_Server_for_your_WQHD_Camera_using_Docker_01.png 460w", "/en/static/fa60ee13abd0dedd243db82b472a76fe/c0255/FTP_Server_for_your_WQHD_Camera_using_Docker_01.png 920w", "/en/static/fa60ee13abd0dedd243db82b472a76fe/f53a0/FTP_Server_for_your_WQHD_Camera_using_Docker_01.png 1062w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/fa60ee13abd0dedd243db82b472a76fe/c0255/FTP_Server_for_your_WQHD_Camera_using_Docker_01.png",
              "alt": "Set up an FTP Server for your WQHD Camera using Docker",
              "title": "Set up an FTP Server for your WQHD Camera using Docker",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <h3 {...{
      "id": "full-hd-cameras",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#full-hd-cameras",
        "aria-label": "full hd cameras permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Full HD Cameras`}</h3>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/7f01d0e899590d396da44abcd8524411/d53ff/FTP_Server_for_your_WQHD_Camera_using_Docker_02.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "46.95652173913043%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAJCAIAAAC9o5sfAAAACXBIWXMAAA7rAAAO6wFxzYGVAAABxUlEQVQoz02RXU/TYBiG+0FYaDdoaXcmi3FrdbQ1ZDBJ33bt+vZrmm3d3retsg5xYgIhyhFRhqAe+EHif9H9AA/8aWYdolfu0yt37uchtBxVe/igdtd9fok+/+q+/9GZ/gzPb9KZzsLp7MnFLPv+u7V3Dm2jvqlUKpVqtUps5iiKItXuedF4dPolObpMjq9uMzicRi/fdQ/OkuOPIEBlYV0URZZlOY4jlBxVVSRZdmwzS4eo/3jY6yyCep22sas3t3YbmgWajS2NpmhmhSksFxiGuZHnzZIEobs3yhDGCMe38f3AbFmGYXqeD4DBc3ypWGIZdrW0StTrdVVVFUWRZRlCmGVZHMfoLxhjx3EAALqu2zllsbxEL5EkyTLsXF40y7LsQGecjZ6mCcb/bM8PbNs2dd2FEADArDBFtrhWWptv/l9uO84gHYf9uB8N0TAn6vptw7KM7ZZpeO7OTrOwXOA5XhREYV2Yy4tr1yQp9Jxvb9D162gywihOkxink9Pk5NP+/slFr/8hfQaajwiCoEiSpmme5+evWmyW5PvQNq6P/K+HVtx1oRcEHgzjV/7kajB4cWaYb92woWoUTVMUJQrixp2NPyQPgO92MmLPAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/7f01d0e899590d396da44abcd8524411/e4706/FTP_Server_for_your_WQHD_Camera_using_Docker_02.avif 230w", "/en/static/7f01d0e899590d396da44abcd8524411/d1af7/FTP_Server_for_your_WQHD_Camera_using_Docker_02.avif 460w", "/en/static/7f01d0e899590d396da44abcd8524411/7f308/FTP_Server_for_your_WQHD_Camera_using_Docker_02.avif 920w", "/en/static/7f01d0e899590d396da44abcd8524411/ca993/FTP_Server_for_your_WQHD_Camera_using_Docker_02.avif 1068w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/7f01d0e899590d396da44abcd8524411/a0b58/FTP_Server_for_your_WQHD_Camera_using_Docker_02.webp 230w", "/en/static/7f01d0e899590d396da44abcd8524411/bc10c/FTP_Server_for_your_WQHD_Camera_using_Docker_02.webp 460w", "/en/static/7f01d0e899590d396da44abcd8524411/966d8/FTP_Server_for_your_WQHD_Camera_using_Docker_02.webp 920w", "/en/static/7f01d0e899590d396da44abcd8524411/55b8e/FTP_Server_for_your_WQHD_Camera_using_Docker_02.webp 1068w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/7f01d0e899590d396da44abcd8524411/81c8e/FTP_Server_for_your_WQHD_Camera_using_Docker_02.png 230w", "/en/static/7f01d0e899590d396da44abcd8524411/08a84/FTP_Server_for_your_WQHD_Camera_using_Docker_02.png 460w", "/en/static/7f01d0e899590d396da44abcd8524411/c0255/FTP_Server_for_your_WQHD_Camera_using_Docker_02.png 920w", "/en/static/7f01d0e899590d396da44abcd8524411/d53ff/FTP_Server_for_your_WQHD_Camera_using_Docker_02.png 1068w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/7f01d0e899590d396da44abcd8524411/c0255/FTP_Server_for_your_WQHD_Camera_using_Docker_02.png",
              "alt": "Set up an FTP Server for your WQHD Camera using Docker",
              "title": "Set up an FTP Server for your WQHD Camera using Docker",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <h3 {...{
      "id": "wqhd-cameras",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#wqhd-cameras",
        "aria-label": "wqhd cameras permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`WQHD Cameras`}</h3>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/32b96875a943e7ab4af5ba7af4dc176f/2e367/FTP_Server_for_your_WQHD_Camera_using_Docker_03.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "56.086956521739125%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAIAAADwazoUAAAACXBIWXMAAA7rAAAO6wFxzYGVAAACAElEQVQoz2WRXW/SUByHjzdrddFYeVFAYaMtEU4h0TihZaen55R2jI4y2vJS6ZgvS4wGL0TGsl0tUaeJya70S0zuTfx0Zh1hW3zyu33yf/mBUqkkSZJULD0WVklw1Dr+0zg4axzOjOlvY/885nRmTGf1g7P+yd9qd5+oilQsptNpnudBIQRCSeSzpvsqmJx2R197H046o3nc91+cd5/bb49ffPyBm4P0oxR3L8IyDHeXAzBEglDM5Yha7bvN7S2zvbWxiK7KWHm2XnlK1stVeS2WeMAyLACAYZi5DCEURZFSfWe42+v7jutdxHU9QvWKrJRlBWsEYTWZiEa4OwCAmwwD8vk8hLBQKIQy9X3fdV3nCqZpYowRQrpew2o18zCSiEduAMAuZAhhLpejlAZB4F2XNU2TZblSqSCkEg0Jq/cTcQ78LxNCfN/veN5VuRliWZZt2xijdCYZi0Xna4evXtxMd4eB3+9dznY9q2lv1Dfrptm0LI2oWSF9a3n5XGbZeVUXN2uE2p3AbHbsVttph7SsGpYRUp4gVKYUE5XjboMQdmnpsipeEDcN8nPi/BrbewPX8bodz+nuTfqfTl+/GX9rbX/v9A2Mo/FYKplKJpKpVGpRVUEUcxpSjl7WDndwu6HTmmnUdNMZGsHYbg1GSB1RQ1l7nllZEQSB5/lsNvsPGfmexmQGHZcAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/32b96875a943e7ab4af5ba7af4dc176f/e4706/FTP_Server_for_your_WQHD_Camera_using_Docker_03.avif 230w", "/en/static/32b96875a943e7ab4af5ba7af4dc176f/d1af7/FTP_Server_for_your_WQHD_Camera_using_Docker_03.avif 460w", "/en/static/32b96875a943e7ab4af5ba7af4dc176f/7f308/FTP_Server_for_your_WQHD_Camera_using_Docker_03.avif 920w", "/en/static/32b96875a943e7ab4af5ba7af4dc176f/730d0/FTP_Server_for_your_WQHD_Camera_using_Docker_03.avif 1066w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/32b96875a943e7ab4af5ba7af4dc176f/a0b58/FTP_Server_for_your_WQHD_Camera_using_Docker_03.webp 230w", "/en/static/32b96875a943e7ab4af5ba7af4dc176f/bc10c/FTP_Server_for_your_WQHD_Camera_using_Docker_03.webp 460w", "/en/static/32b96875a943e7ab4af5ba7af4dc176f/966d8/FTP_Server_for_your_WQHD_Camera_using_Docker_03.webp 920w", "/en/static/32b96875a943e7ab4af5ba7af4dc176f/fbb05/FTP_Server_for_your_WQHD_Camera_using_Docker_03.webp 1066w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/32b96875a943e7ab4af5ba7af4dc176f/81c8e/FTP_Server_for_your_WQHD_Camera_using_Docker_03.png 230w", "/en/static/32b96875a943e7ab4af5ba7af4dc176f/08a84/FTP_Server_for_your_WQHD_Camera_using_Docker_03.png 460w", "/en/static/32b96875a943e7ab4af5ba7af4dc176f/c0255/FTP_Server_for_your_WQHD_Camera_using_Docker_03.png 920w", "/en/static/32b96875a943e7ab4af5ba7af4dc176f/2e367/FTP_Server_for_your_WQHD_Camera_using_Docker_03.png 1066w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/32b96875a943e7ab4af5ba7af4dc176f/c0255/FTP_Server_for_your_WQHD_Camera_using_Docker_03.png",
              "alt": "Set up an FTP Server for your WQHD Camera using Docker",
              "title": "Set up an FTP Server for your WQHD Camera using Docker",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/en/FAQs/"
        }}>{`FAQs`}</a>
        <ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-9420WQHD/"
            }}>{`IN-9420 WQHD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-9408WQHD/"
            }}>{`IN-9408 WQHD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8415WQHD/"
            }}>{`IN-8415 WQHD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8403WQHD/"
            }}>{`IN-8403 WQHD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8401WQHD/"
            }}>{`IN-8401 WQHD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-9020FHD/"
            }}>{`IN-9020 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-9010FHD/"
            }}>{`IN-9010 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-9008FHD/"
            }}>{`IN-9008 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8015FHD/"
            }}>{`IN-8015 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8003FHD/"
            }}>{`IN-8003 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8001FHD/"
            }}>{`IN-8001 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-7011HD/"
            }}>{`IN-7011 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-6014HD/"
            }}>{`IN-6014 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-6012HD/"
            }}>{`IN-6012 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-6001HD/"
            }}>{`IN-6001 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-5907HD/"
            }}>{`IN-5907 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-5905HD/"
            }}>{`IN-5905 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/VGA_Series/"
            }}>{`VGA Series`}</a></li>
        </ul>
      </li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      